import axios from 'axios'
import { useGtag } from 'vue-gtag-next'

const apiBaseUrl = process.env.VUE_APP_APIBASEURL
const gaEnabled = JSON.stringify(process.env.VUE_APP_INJECT_GA) === JSON.stringify('true')

const api = {
  isLoginRedirectCallback: false,
  setAnalyticsUserId: function () {
    if (!gaEnabled) {
      return
    }
    const schoolId = localStorage.getItem('schoolId') || ''
    if (schoolId) {
      console.debug('Setting tracking user id to', schoolId)
      const { set } = useGtag()
      set({ user_id: schoolId })
    }
  },
  fireAnalyticsEvent: function (action: any) {
    if (!gaEnabled) {
      return
    }
    const schoolId = localStorage.getItem('schoolId') || ''
    if (schoolId) {
      console.debug('Sending tracking event', action, schoolId)
      const { event } = useGtag()
      // https://developers.google.com/analytics/devguides/collection/gtagjs/events
      event(action, {
        event_category: 'Kiosk'
      })
    }
  },
  createApiClient: async function () {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      return axios.create({
        baseURL: apiBaseUrl,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'x-auth-mode': '1',
          'x-site-key': 'kiosk'
        }
      })
    } else {
      return axios.create({ baseURL: apiBaseUrl })
    }
  },
  saveNewLogin: function (data: any) {
    localStorage.setItem('accessToken', data.accessToken)
    localStorage.setItem('refreshToken', data.refreshToken)
    localStorage.setItem('schoolId', data.schoolId)
    localStorage.setItem('schoolName', data.schoolName)
    localStorage.setItem('schoolPhotoUrl', data.schoolPhotoUrl)
    localStorage.setItem('screensaverPhotoUrl', data.screensaverPhotoUrl)
    this.setAnalyticsUserId()
  },
  getLoginData: function () {
    return {
      schoolId: localStorage.getItem('schoolId'),
      schoolName: localStorage.getItem('schoolName'),
      schoolPhotoUrl: localStorage.getItem('schoolPhotoUrl'),
      screensaverPhotoUrl: localStorage.getItem('screensaverPhotoUrl')
    }
  },
  logout: async function () {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('schoolId')
    localStorage.removeItem('schoolName')
    localStorage.removeItem('schoolPhotoUrl')
    localStorage.removeItem('screensaverPhotoUrl')
  },
  getIsLoggedIn: async function () {
    const accessToken = localStorage.getItem('accessToken')
    return !!accessToken
  },
  handleFailedRequest: function (ex: any) {
    // console.debug('e', JSON.stringify(ex))
    const status = ex.status || (ex.response ? ex.response.status : 0)
    const data = {
      code: status,
      validationErrors: [] as any[],
      error: ''
    }
    if (status === 401) {
      data.validationErrors = ['Login failed']
    } else if (status === 400) {
      const errArr = ex.response?.data?.errors || []
      if (errArr.length > 0) {
        data.validationErrors = ex.response.data?.errors || ['Invalid request']
      } else {
        data.validationErrors = [ex.message || 'Unexpected error (code 400)']
      }
    } else if (status === 404) {
      data.validationErrors = ['Item cant be found']
    } else {
      data.error = 'Oops, unexpected error! (error code ' + status + ')'
    }
    return data
  }
}

export default api
