<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: { },
  name: 'LogoutPage',
  data () {
    return {
    }
  },

  async mounted () {
    console.log('mounted')
  },

  methods: {
    login: function () {
      this.$router.push('/login')
    }
  }
})
</script>

<template>
  <div class="row margin-large-top-bottom">
    <div class="col s12 l6 offset-l3">
      <div class="card-panel center-align blue darken-4" id="login-form">

        <div>
          <img src="@/assets/oss-logo-words.png" class="responsive-img oss-logo-full margin-large-top-bottom" />
        </div>

        <div class="margin-large-top-bottom" v-if="!isLoggedIn">
          <a class="btn blue" @click="login()" data-test-id="login-form-submit">login</a>
        </div>

      </div>
    </div>
  </div>
</template>
