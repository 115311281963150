<script>
import { defineComponent } from 'vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'
import { useGtag } from 'vue-gtag-next'

let screensaverTimeout = null
window.screensaverTimeoutMs = (1 * 60) * 1000 // 1 minute
const msInactivityToScreensaver = () => window.screensaverTimeoutMs

const messages = {
  serverError: 'A server error occurred, please refresh the page and try again.',
  loginExpiredError: 'Your login has expired, please refresh the page and try again.'
}

const apiSpec = {
  auth: 'auth?siteKey=kiosk',
  authCheck: 'auth?siteKey=kiosk',
  searchForStudentByString: 'signin/students?search=',
  searchForClassesByStudent: 'signin/classes?studentId=',
  checkSignIn: 'signin/',
  createSignIn: 'signin',
  guestCreate: 'guest'
}

export default defineComponent({
  components: { LoadingDisplay, ExceptionDisplay, ValidationErrorDisplay },
  name: 'SignInPage',
  data () {
    return {
      gtag: {},
      photoCacheBusterQueryString: '',
      search: {
        searchTerm: '',
        students: [],
        isLoading: false,
        message: ''
      },
      signin: {
        isOpen: false,
        selectedStudent: {},
        studentPhotoUrl: '',
        isLoading: false,
        availableClasses: [],
        modalErrorMessage: '',
        showCheckInSuccess: false,
        reset: function () {
          this.isOpen = false
          this.selectedStudent = {}
          this.studentPhotoUrl = ''
          this.isLoading = false
          this.availableClasses = []
          this.modalErrorMessage = ''
          this.showCheckInSuccess = false
        }
      },
      school: {
        schoolPhotoUrl: '',
        photoCantBeFound: false
      },
      screensaver: {
        isVisible: false,
        disabled: false, // KILLSWITCH
        photoUrl: '' // 'https://www.axisbjj.co.nz/wp-content/uploads/2020/08/107662466_3303221136375455_2127542202321897685_o.jpg'
      },
      guest: {
        isOpen: false,
        isLoading: false,
        error: '',
        validationErrors: [],
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      }
    }
  },

  async mounted () {
    this.gtag = useGtag()
    // ensure all photos are loaded uncached, incase the photos have been updated
    this.photoCacheBusterQueryString = '?cacheBuster=' + (Math.random() + '').substring(2, 6)
    var userData = this.$api.getLoginData()
    this.school.schoolPhotoUrl = userData.schoolPhotoUrl
    this.school.photoCantBeFound = false
    this.screensaver.photoUrl = userData.screensaverPhotoUrl
    this.setupRefreshTimer()
    this.resetScreensaverTimeout()
  },

  methods: {
    toggleFullScreen: function () {
      const isFullScreen = !!(document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        null)
      if (isFullScreen) {
        console.debug('is full screen, existing..')
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen()
        }
      } else {
        console.debug('is not full screen, requesting..')
        var elem = document.documentElement
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen()
        }
      }
    },
    searchOnKeyUp: function (e) {
      if (e.keyCode === 13) { // enter key
        this.runSearch(this.search.searchTerm)
      }
    },
    runSearch: async function (searchString) {
      if (!searchString || !searchString.length) {
        return
      }

      this.search.message = ''
      this.search.isLoading = true
      var url = apiSpec.searchForStudentByString + searchString

      // get data from api
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get(url)
        .then((d) => { this.onSearchResultsSuccess(d.data) })
        .catch((e) => { this.onSearchResultsFailure(e) })
        .then(() => {
          this.search.isLoading = false
          this.resetScreensaverTimeout()
        })
    },
    onSearchResultsSuccess: function (list) {
      this.search.students = list

      if (!list || !list.length) {
        this.search.message = 'No results found'
      }

      if (list.length === 1) {
        this.onSelectedStudent(list[0])
      }
    },
    onSearchResultsFailure: function (e) {
      this.search.message = this.getErrorMessage(e)
    },
    setupRefreshTimer: function () {
      var d = new Date()
      var n = d.getHours()

      var hoursUntilMidnight = 24 - n
      var timeoutMs = ((hoursUntilMidnight * 60) * 60) * 1000

      console.debug('Hour of day', n)
      console.debug('Auto reloading page in ms: ', timeoutMs)
      const apiRef = this.$api

      // reload page at around midnight
      setTimeout(function () {
        // tracking
        apiRef.fireAnalyticsEvent('ReloadingApp')
        // strip off any existing query string/cache busting
        var formattedUrl = window.location.href.replace(window.location.search, '')
        // add random cache busting query at end
        window.location.href = formattedUrl + '?b=' + (Math.random() + '').substring(2, 6)
      }, timeoutMs)
    },
    onSelectedStudent: async function (student) {
      this.signin.reset()
      this.signin.selectedStudent = student
      this.signin.selectedStudent.canSignIn = false // reset
      this.signin.isOpen = true
      this.signin.isLoading = true

      var url = apiSpec.checkSignIn + this.signin.selectedStudent.id

      // get data from api
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get(url)
        .then(d => { this.onSelectedStudentSuccess(d.data) })
        .catch(e => { this.onSelectedStudentFailure(e) })
        .then(() => { this.signin.isLoading = false })
    },
    onSelectedStudentSuccess: function (data) {
      this.signin.isLoading = false
      this.signin.availableClasses = data?.availableClasses || []
      if (this.signin.availableClasses.length === 1) {
        this.signin.availableClasses[0].isSelected = true
      }
      this.signin.selectedStudent.canSignIn = true
    },
    onSelectedStudentFailure: function (e) {
      this.signin.modalErrorMessage = this.getErrorMessage(e)
    },
    checkInSelectedStudent: async function () {
      var selectedClasses = this.signin.availableClasses.filter((c) => c.isSelected)
      var selectedClassesIds = selectedClasses.map((c) => c.id)

      var data = {
        id: this.signin.selectedStudent.id,
        selectedClassIds: selectedClassesIds
      }

      this.signin.modalErrorMessage = ''
      this.signin.isLoading = true

      const apiClient = await this.$api.createApiClient()
      apiClient
        .post(apiSpec.createSignIn, JSON.stringify(data))
        .then((d) => { this.onCheckInSuccess() })
        .catch((e) => { this.onCheckInFailure(e) })
        .then(() => { this.signin.isLoading = false })
    },
    onCheckInSuccess: function () {
      // show check in success
      this.signin.showCheckInSuccess = true

      setTimeout(() => {
        this.resetStudentSelect()
      }, 2000)

      this.sendSignInEvent()
    },
    onCheckInFailure: function (e) {
      this.signin.modalErrorMessage = this.getErrorMessage(e)
    },
    resetStudentSelect: function () {
      this.search.students = []
      this.search.searchTerm = ''
      this.signin.reset()
    },
    sendSignInEvent: function () {
      this.$api.fireAnalyticsEvent('SignInManual')
    },
    stopScreensaverTimeout: function () {
      if (!screensaverTimeout || this.screensaver.disabled) {
        return
      }
      console.debug('stopScreensaverTimeout')
      try {
        clearTimeout(screensaverTimeout)
        screensaverTimeout = null
      } catch {
      }
    },
    resetScreensaverTimeout: function () {
      // master turn off switch
      if (this.screensaver.disabled) {
        console.debug('screensaver disabled')
        return
      }

      if (screensaverTimeout) {
        this.stopScreensaverTimeout()
      }
      console.debug('resetScreensaverTimeout')
      this.screensaver.isVisible = false
      screensaverTimeout = setTimeout(() => { this.screensaver.isVisible = true }, msInactivityToScreensaver())
    },
    openGuestModal: function () {
      this.guest.isLoading = false
      this.guest.error = ''
      this.guest.validationErrors = []
      this.guest.firstName = ''
      this.guest.lastName = ''
      this.guest.email = ''
      this.guest.phone = ''
      this.guest.isOpen = true
      this.stopScreensaverTimeout()
    },
    closeGuestModal: function () {
      this.guest.isOpen = false
      this.resetScreensaverTimeout()
    },
    createGuest: async function () {
      var model = {
        firstName: this.guest.firstName,
        lastName: this.guest.lastName,
        email: this.guest.email,
        phone: this.guest.phone
      }

      this.guest.isLoading = true
      this.guest.error = ''
      this.guest.validationErrors = []

      const apiClient = await this.$api.createApiClient()
      apiClient
        .post(apiSpec.guestCreate, JSON.stringify(model))
        .then((d) => this.onCreateGuestSuccess(d))
        .catch((e) => this.onCreateGuestFailure(e))
        .then(() => {
          this.guest.isLoading = false
        })
    },
    onCreateGuestSuccess: function (d) {
      // close modal
      this.guest.isOpen = false
      document.popToast('Guest successfully created!')
      this.runSearch(d.data.id)
    },
    onCreateGuestFailure: function (e) {
      const data = this.getErrorData(e)
      this.guest.error = data.error
      this.guest.validationErrors = data.validationErrors
    },
    getErrorData: function (e) {
      const data = this.$api.handleFailedRequest(e)
      if (data.code === 401) {
        this.$router.push('/login')
      }
      return data
    },
    getErrorMessage: function (e) {
      const data = this.getErrorData(e)
      if (data.error) {
        return data.error
      } else {
        return data.validationErrors.length > 0 ? data.validationErrors[0] : messages.serverError + ' (code ' + data.code + ')'
      }
    },
    logout: async function () {
      await this.$api.logout()
      this.$router.push('/logout')
    }
  }
})
</script>

<style>
  .oss-blue-bg {
    background-color: #0D47A1;
  }
  .fullscreen {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  .fullscreen .center-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .screensaver-outer {
     background-repeat: no-repeat !important;
     background-position: 50% 50% !important;
     background-size: cover !important;
  }
  .screensaver-inner {
      padding: 20px 50px;
  }
  .button-strip a {
      margin-right: 4px !important;
  }

/* ======= ERRORS ======= */

  .error-message {
      background-color: #ffebee;
      color: #b71c1c;
      padding: 5px;
      margin-bottom: 5px;
  }

  .error-message i {
      margin: 0 5px;
  }

/* ======= ERRORS ======= */

.topleft-hidden {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.topright-hidden {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}
</style>

<template>

  <div v-show="!screensaver.isVisible" class="container">
    <div class="row"></div>

    <!-- student search -->
    <div class="row" v-if="!signin.isOpen && !guest.isOpen" data-test-id="student-search">

      <!-- header -->
      <div class="col s12 m10 offset-m1">
        <div class="card-panel">
          <div class="section">

            <div class="right-align">
              <a class="btn blue" @click="openGuestModal()" data-test-id="guest-open-modal">
                <i class="material-icons left">input</i> Guest</a>
            </div>

            <div class="center-align">
              <img v-show="school.photoCantBeFound || !school.schoolPhotoUrl" src="@/assets/school-placeholder.jpg"
                  class="avatar responsive-img circle z-depth-1 photo" />
              <img v-show="!school.photoCantBeFound" :src="school.schoolPhotoUrl"
                  @error="school.photoCantBeFound = true"
                  class="avatar responsive-img circle z-depth-1 photo" />
            </div>

            <h5 class="center-align">
                Student Sign-In
            </h5>

          </div>

          <!-- search box -->
          <div class="input-field left-align">
                <i class="material-icons prefix">search</i>
            <input id="search-box" v-model="search.searchTerm" type="search" placeholder="Search here" v-on:keyup="searchOnKeyUp" v-on:keyup.enter="$event.target.blur()" @focus="stopScreensaverTimeout()" @blur="resetScreensaverTimeout()">
          </div>
        </div>
      </div>

      <!-- search results -->
      <div class="col s12 m10 offset-m1">

        <loading-display :isLoading="search.isLoading" :is-white="false" />

        <!-- message -->
        <div v-if="search.message" class="section center-align">
          <h5 data-test-id="student-search-error-message">
              {{ search.message }}
          </h5>
        </div>

        <!-- search results -->
        <div v-if="!search.isLoading">
          <!-- foreach -->
          <div v-for="s in search.students" :key="s.id">
            <div class="card-panel nowrap clickable" v-on:click="onSelectedStudent(s)" data-test-id="student-item">

              <i class="material-icons right blue-text" style="margin-top: 14px;">input</i>

              <div class=" valign-wrapper">

                <img v-if="s.photoCantBeFound || !s.photoUrl" src="@/assets/student-placeholder.jpg"
                    class="responsive-img circle avatar-small"
                    style="margin-right: 10px; display:inline-block;" />
                <img v-if="!s.photoCantBeFound" :src="s.photoUrl"
                    @error="s.photoCantBeFound = true"
                    class="responsive-img circle avatar-small"
                    style="margin-right: 10px; display:inline-block;" />

                <div class="truncate" style="display: inline-block;">
                  <div class="truncate">
                    <span class="checkin-search-result-name" data-test-id="student-item-name">{{s.firstName}} {{s.lastName}}</span>
                  </div>
                  <div class="truncate">
                    <span>{{s.schoolName}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- /foreach -->
        </div>

      </div>

    </div>
    <!-- /student search -->

    <!-- sign in -->
    <div class="row" v-if="signin.isOpen" data-test-id="check-in">
      <div class="col s12 m10 offset-m1">
        <div class="card center-align">
          <div class="card-content">

              <!-- student info -->
              <div class="section">
                <img v-if="signin.selectedStudent.photoCantBeFound || !signin.selectedStudent.photoUrl" src="@/assets/student-placeholder.jpg"
                      class="responsive-img circle z-depth-1 avatar" />
                <img v-if="!signin.selectedStudent.photoCantBeFound" :src="signin.selectedStudent.photoUrl"
                    @error="signin.selectedStudent.photoCantBeFound = true"
                      class="responsive-img circle z-depth-1 avatar" />

                <h4 class="nowrap" data-test-id="check-in-full-name">{{ signin.selectedStudent.firstName }} {{ signin.selectedStudent.lastName }}</h4>
                <h6>{{ signin.selectedStudent.schoolName }}</h6>

                <p v-if="signin.selectedStudent.lastCheckInFormatted">
                  Last sign in: {{signin.selectedStudent.lastCheckInFormatted}} ago
                </p>

              </div>

              <div v-if="signin.modalErrorMessage" class="left-align" style="display: inline-block;">
                <div class="red-text valign-wrapper">
                  <i class="material-icons margin-right-small">error</i>
                  <strong data-test-id="sign-in-post-error-reason">{{ signin.modalErrorMessage }}</strong>
                </div>
              </div>

              <!-- spinner -->
              <loading-display :isLoading="signin.isLoading" :is-white="false" />

              <!-- success tick -->
              <div v-if="signin.showCheckInSuccess" class="center-align" data-test-id="check-in-success">
                <div><h3>Welcome!</h3></div>
                <div><i class="material-icons large green-text">check_circle</i></div>
              </div>

              <!-- class list -->
              <div v-if="!signin.isLoading && !signin.showCheckInSuccess && signin.selectedStudent.canSignIn">

                <div v-if="signin.availableClasses.length">
                  <div class="section">
                    <strong>
                      Which classes will you be attending?
                    </strong>
                  </div>
                  <div class="left-align" style="display: inline-block;">
                    <div v-for="c in signin.availableClasses" :key="c.id" class="class-selection-item">
                      <label>
                        <input type="checkbox" v-model="c.isSelected" class="filled-in" />
                        <span class="black-text" data-test-id="sign-in-class-item-name" :data-test-value="c.name">
                          {{ c.name }}
                          <span v-if="c.time">({{ c.time }})</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="left-align" style="display: inline-block;">
                    <div class="orange-text valign-wrapper">
                      <i class="material-icons margin-right-small">error</i>
                      <strong>There are no available classes to sign in to for today, or for your current plan.</strong>
                    </div>
                  </div>
                </div>

              </div>

          </div>
          <!-- buttons -->
          <div class="card-action button-strip">
            <a href="#"
              class="waves-effect waves-blue blue btn-large"
              v-if="!signin.isLoading && !signin.showCheckInSuccess && signin.selectedStudent.canSignIn"
              v-on:click="checkInSelectedStudent()"
              data-test-id="check-in-login-button">
              <i class="material-icons left">login</i>
              Sign In
            </a>
            <a href="#"
              class="waves-effect waves-grey grey btn-large"
              v-on:click="resetStudentSelect()"
              data-test-id="check-in-cancel-button">
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- /sign in -->

    <!-- guest modal -->
    <div class="row" v-if="guest.isOpen" data-test-id="guest-modal">
      <div class="card-panel">
        <div class="row">
          <div class="col s12 center-align">
            <h4>
                New Guest
            </h4>
            <loading-display :isLoading="guest.isLoading" :is-white="false" />
          </div>
        </div>

        <div class="center-align">
          <exception-display :error="guest.error"></exception-display>
          <validation-error-display :validationErrors="guest.validationErrors"></validation-error-display>
        </div>

        <div v-if="!guest.isLoading">
          <div class="row">
            <div class="col s12 l6">
              <strong>First Name</strong>
              <input type="text" v-model="guest.firstName" data-test-id="guest-first-name" />
            </div>
            <div class="col s12 l6">
              <strong>Last Name</strong>
              <input type="text" v-model="guest.lastName" data-test-id="guest-last-name" />
            </div>
          </div>

          <div class="row">
            <div class="col s12 l6">
              <strong>Email</strong>
              <input type="email" v-model="guest.email" data-test-id="guest-email" />
            </div>
            <div class="col s12 l6">
              <strong>Phone</strong>
              <input type="tel" v-model="guest.phone" data-test-id="guest-phone" />
            </div>
          </div>

          <div class="row">
            <label>
              <input class="filled-in" type="checkbox" checked="checked" />
              <span>I have filled out and agree to the liability waiver provided by an instructor</span>
            </label>
          </div>
        </div>

        <!-- actions -->
        <div class="modal-footer button-strip">
            <a href="#!" class="waves-effect waves-blue btn grey" v-if="!guest.isLoading" v-on:click="closeGuestModal()" data-test-id="guest-cancel-button">Cancel</a>
            <a href="#!" class="waves-effect waves-blue btn blue" v-if="!guest.isLoading" v-on:click="createGuest()" data-test-id="guest-create-button">Create</a>
        </div>
      </div>
    </div>
    <!-- /guest modal -->

  </div>

  <a class="btn red topleft-hidden" @click="logout()" data-test-id="logout-action">Logout</a>
  <a class="btn red topright-hidden" @click="toggleFullScreen()" data-test-id="fullscreen-action">Fullscreen</a>

  <!-- screensaver -->
  <div
    v-show="screensaver.isVisible"
    @click="resetScreensaverTimeout()"
    class="oss-blue-bg fullscreen screensaver-outer"
    :style="[screensaver.photoUrl ? { background: 'linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.1)), url(\'' + screensaver.photoUrl + photoCacheBusterQueryString + '\')' } : {}]"
    data-test-id="screensaver">

    <!-- inner button -->
    <div @click="resetScreensaverTimeout()" class=" center-screen center-align screensaver-inner card-panel">

      <div>
        <img v-show="school.photoCantBeFound || !school.schoolPhotoUrl" src="@/assets/oss-logo-circle-blue.png"
            class="avatar responsive-img photo" />
        <img v-show="!school.photoCantBeFound" :src="(school.schoolPhotoUrl + photoCacheBusterQueryString)"
            @error="school.photoCantBeFound = true"
            class="avatar responsive-img circle z-depth-1 photo" />
      </div>

      <div>
        <h4 @click="resetScreensaverTimeout()" class="black-text"><strong>Tap to Sign In</strong></h4>
      </div>

    </div>

  </div>
  <!--/ screensaver -->

</template>
