<script lang="ts">
export default {
  props: {
    validationErrors: Array
  }
}
</script>

<template>
  <div v-if="!!validationErrors && validationErrors.length > 0" class="row" data-test-id="validation-error-message-box">
    <div class="error-message">
      <p v-for="e in validationErrors" :key="e" class="valign-wrapper">
        <i class="material-icons">error</i>
        <span data-test-id="validation-error-message">{{ e }}</span>
      </p>
    </div>
  </div>
</template>
