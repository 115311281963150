<template>
  <div class="container center" v-if="!isBrowserValid">
    <h4>Oss Gym: Kiosk</h4>
    <loading-display :isLoading="isLoading" />
    <exception-display :error="error"></exception-display>
    <div v-if="isLoading">Initializing...</div>
  </div>
  <main>
    <div v-if="isBrowserValid">
      <router-view />
    </div>
  </main>
</template>

<script>
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
export default {
  components: { LoadingDisplay, ExceptionDisplay },
  data () {
    return {
      isBrowserValid: false,
      isLoading: true,
      error: ''
    }
  },
  async created () {
    await this.runChecks()
  },
  errorCaptured (errMsg, vm, info) {
    document.popToast('Sorry! There has been an unhandled error. Please refresh the page and try again')
  },
  methods: {
    runChecks: async function () {
      this.isBrowserValid = false
      this.error = ''
      this.isLoading = true

      var isLocalStorageEnabled = await this.doLocalStorageCheck()
      if (!isLocalStorageEnabled) {
        this.isLoading = false
        this.error = 'No local storage is available on this web browser, please try another one'
        return
      }

      this.isBrowserValid = true

      this.$router.push('/login')
    },
    doLocalStorageCheck: function () {
      try {
        var mod = 'haslocalstoragecheck'
        localStorage.setItem(mod, mod)
        localStorage.removeItem(mod)
        return true
      } catch (e) {
        return false
      }
    }
  }
}
</script>

<style>
.margin-right-small {
  margin-right: 2px;
}

.margin-small {
    margin: 2px;
}

.margin-small-bottom {
    margin-bottom: 2px;
}

.margin-medium {
    margin: 5px;
}

.margin-large {
    margin: 10px;
}

.margin-large-top {
    margin-top: 10px;
}

.margin-large-left {
    margin-left: 10px;
}

.margin-large-right {
    margin-right: 10px;
}

.margin-large-bottom {
    margin-bottom: 10px;
}
.margin-large-top-bottom {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-medium-top-bottom {
    margin-top: 5px;
    margin-bottom: 5px;
}

.margin-medium-left {
    margin-left: 5px;
}

.margin-medium-right {
    margin-right: 5px;
}

.margin-medium-bottom {
    margin-bottom: 5px;
}

.padding-small {
    padding: 2px;
}

.padding-medium {
    padding: 5px;
}

.padding-medium-top-bottom {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-medium-left {
    padding-left: 5px;
}

.padding-medium-right {
    padding-right: 5px;
}

.padding-medium-bottom {
    padding-bottom: 5px;
}

/***** Materialize overrides ******/

/* input fieldset active highlight */
.input-field .prefix.active {
  color: #0D47A1;
}

/***** / Materialize overrides ******/

/***** SIGN IN PAGE ******/

.class-selection-item {
  padding: 15px 0;
}

.class-selection-item label {
  padding: 15px 60px;
}

img.avatar-small {
  max-height: 50px;
  min-height: 50px;
  height: 50px;
}

img.avatar-medium {
  max-height: 80px;
  min-height: 80px;
  height: 80px;
}

@media screen and (min-width: 601px) {

  img.avatar {
    max-height: 120px;
    min-height: 120px;
    height: 120px;
  }
}

@media screen and (max-width: 600px) {

  img.avatar {
    max-height: 80px;
    min-height: 80px;
    height: 80px;
  }
}

/***** / SIGN IN PAGE ******/

/***** SPINNER ******/

/* < SPINNER from LOADING.IO > */
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #0D47A1;
    border-color: #0D47A1 transparent #0D47A1 transparent;
    animation: lds-dual-ring 0.7s linear infinite;
}

.lds-dual-ring-white {
    border: 4px solid #FFF;
    border-color: #FFF transparent #FFF transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* < SPINNER from LOADING.IO > */

/* < SPINNER from LOADING.IO > */
.lds-dual-ring-white {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 0;
}

.lds-dual-ring-white:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-color: #FFF transparent #FFF transparent;
    animation: lds-dual-ring 0.7s linear infinite;
}

@keyframes lds-dual-ring-white {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* < SPINNER from LOADING.IO > */

</style>
