import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'
import LoginPage1 from '../views/LoginPage1.vue'
import LogoutPage from '../views/LogoutPage.vue'
import SignInPage from '../views/SignInPage.vue'

const routes:RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Log in',
    component: LoginPage1,
    meta: {
      title: 'Log in'
    }
  },
  {
    path: '/logout',
    name: 'Log out',
    component: LogoutPage,
    meta: {
      title: 'Log out'
    }
  },
  {
    path: '/',
    name: 'SignIn',
    component: SignInPage,
    meta: {
      title: 'Sign In'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

router.beforeEach(async (toRoute, fromRoute, next) => {
  console.debug('from', fromRoute.path, 'to', toRoute.path)
  let routeTitle = 'Oss Gym'

  if (toRoute.meta) {
    if (typeof toRoute.meta.title === 'string') {
      routeTitle = toRoute.meta.title + ' | ' + routeTitle
    }
  }

  window.document.title = routeTitle

  next()
})

export default router
