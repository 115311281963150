<script>
import { defineComponent } from 'vue'
import LoadingDisplay from '@/components/LoadingDisplay.vue'
import ExceptionDisplay from '@/components/ExceptionDisplay.vue'
import ValidationErrorDisplay from '@/components/ValidationErrorDisplay.vue'

export default defineComponent({
  components: { LoadingDisplay, ExceptionDisplay, ValidationErrorDisplay },
  name: 'LoginPage1',
  data () {
    return {
      isLoading: false,
      isLoggedIn: false,
      error: '',
      validationErrors: [],
      email: '',
      password: ''
    }
  },

  async mounted () {
    this.isLoggedIn = await this.$api.getIsLoggedIn()
    if (this.isLoggedIn) {
      await this.authCheck()
    }
  },

  methods: {
    authCheck: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const apiClient = await this.$api.createApiClient()
      apiClient
        .get('auth?siteKey=kiosk')
        .then((r) => { this.handleAuthSuccess(r) })
        .catch((e) => { this.handleFailedRequest(e) })
        .then(() => { this.isLoading = false })
    },
    login: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      const apiClient = await this.$api.createApiClient()
      const data = {
        schoolId: this.email,
        pin: this.password
      }
      apiClient
        .post('auth?siteKey=kiosk', JSON.stringify(data))
        .then((r) => { this.handleAuthSuccess(r) })
        .catch((e) => { this.handleFailedRequest(e) })
        .then(() => { this.isLoading = false })
    },
    logout: async function () {
      this.error = ''
      this.validationErrors = []
      this.isLoading = true
      await this.$api.logout()
    },
    handleAuthSuccess: async function (d) {
      var data = d.data
      this.$api.saveNewLogin(data)
      this.$router.push('/')
    },
    handleFailedRequest: function (e) {
      const data = this.$api.handleFailedRequest(e)
      this.validationErrors = data.validationErrors
      this.error = data.error
    }
  }
})
</script>

<template>
  <div class="row margin-large-top-bottom">
    <div class="col s12 l6 offset-l3">
      <div class="card-panel center-align blue darken-4" data-test-id="login-form">

        <div>
          <img src="@/assets/oss-logo-words.png" class="responsive-img oss-logo-full margin-large-top-bottom" />
        </div>

        <div class="left-align">
          <exception-display :error="error"></exception-display>
          <validation-error-display :validationErrors="validationErrors"></validation-error-display>
        </div>

        <loading-display :isLoading="isLoading" :is-white="true" />

        <div v-if="isLoading" class="white-text">
          Logging in...
        </div>
        <div v-else class="margin-large-top-bottom">
          <div class="row">
            <div class="col s6 left-align">
              <strong class="white-text">Email</strong>
              <input type="email" v-model="email" data-test-id="login-email" class="white-text" />
            </div>
            <div class="col s6 left-align">
              <strong class="white-text">Password</strong>
              <input type="password" v-model="password" data-test-id="login-password" class="white-text" />
            </div>
          </div>
          <div class="margin-large-top-bottom" v-if="!isLoggedIn">
            <a class="btn blue" @click="login()" data-test-id="login-form-submit">login</a>
          </div>
          <div class="margin-large-top-bottom" v-if="isLoggedIn">
            <a class="btn red" @click="logout()">logout</a>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>
