<script lang="ts">
export default {
  props: {
    error: String
  }
}
</script>

<template>
  <div v-if="error" class="row" data-test-id="error-message-box">
    <div class="error-message">
      <p class="valign-wrapper">
        <i class="material-icons">error</i> <span data-test-id="error-message">{{ error }}</span></p>
    </div>
  </div>
</template>
